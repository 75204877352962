import React from "react";
import { Box, Typography } from "@mui/material";

const Disclaimer = () => {
  return (
    <Box
      component="div"
      sx={{
        maxWidth: {
          xs: "100%",
          md: "calc(100% - 50px)",
          xl: "calc(100% - 100px)",
        },
        mx: "auto",
        mt: 4,
        mb: 2,
        backgroundColor: "#091A3D",
        border: "1px solid #4B608D",
        py: { xs: 3, md: 4 },
        px: { xs: 3, md: 8 },
        color: "#5A79BA",
      }}
    >
      <Typography sx={{ fontWeight: "bold", mb: 1.6, color: "#5A79BA" }}>
        Disclaimer
      </Typography>
      <Typography sx={{ color: "#5A79BA" }}>
        The information on this website and on the Brick by Brick podcast or
        Ship Show Twitter spaces is provided for informational, educational, and
        entertainment purposes only.  This information is not intended to be and
        does not constitute financial advice, investment advice, trading advice,
        or any other type of advice.  You should not make any decision –
        financial, investment, trading or otherwise – based on any of the
        information presented here without undertaking your own due diligence
        and consulting with a financial adviser.  Trading, including that of
        digital assets or cryptocurrency, has potential rewards as well as
        potential risks involved. Trading may not be suitable for all
        individuals. Recordings of podcast episodes or Twitter spaces events may
        be used in the future.
      </Typography>
    </Box>
  );
};

export default Disclaimer;
