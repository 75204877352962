import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'gatsby'
import { Box, Link } from "@mui/material";
import Arrow from "../images/arrow3.inline.svg";
import Arrow2 from "../images/arrow4.inline.svg";

const btn = {
  background: "transparent",
  border: "1px solid #1CE7C2",
  fontSize: 12,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  height: "40px",
  width: "130px",
  margin: "0 10px",
  transition: ".3s ease-in-out",
  color: "#1CE7C2",
  "& svg": {
    display: "block",
    margin: "0 5px",
  },
  "&:hover": {
    background: "#1CE7C2",
    color: "#051537",
  },
  "&.disabled": {
    pointerEvents: "none",
    opacity: 0.5,
  },
};

const Pagination = ({ pageContext }) => {
  const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } =
    pageContext;

  return (
    <Box
      component="nav"
      role="navigation"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mb: 8,
      }}
    >
      <Box component="div">
        {previousPagePath && (
          <Link
            component={RouterLink}
            to={previousPagePath}
            rel="prev"
            sx={btn}
          >
            <Arrow2 />
            .Previous
          </Link>
        )}
      </Box>
      {numberOfPages > 1 && (
        <Box
          component="div"
          sx={{ px: 5, display: { xs: "none", sm: "block" } }}
        >
          Page {humanPageNumber} of {numberOfPages}
        </Box>
      )}

      <Box component="div">
        {nextPagePath && (
          <Link component={RouterLink} to={nextPagePath} rel="next" sx={btn}>
            .Next <Arrow />
          </Link>
        )}
      </Box>
    </Box>
  );
};

Pagination.propTypes = {
    pageContext: PropTypes.object.isRequired,
}

export default Pagination
