import * as React from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../components/Layout"
import bricks from '../images/connect/bricks.svg';
import BlogPost from '../components/BlogPost';
import { Box, Typography} from "@mui/material";
import CompositionsNav from "../components/CompositionsNav";
import Pagination from '../components/Pagination';
import { StaticImage } from "gatsby-plugin-image"
import { MetaData } from "../components/common/meta";
import Disclaimer from "../components/Disclaimer";
import Sidebar from "../components/Sidebar";

const Tag = ({
  data,
  location,
  pageContext,
}: {
  data: any;
  location: PageProps;
  pageContext: any;
}) => {
  const tag = data.ghostTag;
  const posts = data.allGhostPost.edges;
  const authors = data.allGhostAuthor.edges;
  return (
    <Layout>
      <MetaData data={data} location={location} type="series" />
      <Box
        component="span"
        sx={{
          position: "absolute",
          left: "65px",
          top: "200px",
          height: "calc(100% - 415px)",
          width: 0,
          borderLeft: "1px solid #4B608D",
          display: { xs: "none", xl: "block" },
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-5px",
            left: "-8px",
            width: "15px",
            height: "5px",
            backgroundColor: "#1CE7C2",
          },
        }}
      />

      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="img"
          src={bricks}
          sx={{
            position: "absolute",
            left: 0,
            width: "100%",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: -1,
            pointerEvents: "none",
          }}
        />

        <Box
          component="div"
          sx={{
            maxWidth: 750,
            mx: "auto",
            mt: { xs: 12, md: 20 },
            mb: { xs: 6, md: 12 },
            textAlign: "center",
          }}
        >
          <Typography
            sx={{ mb: 3, color: "#1CE7C2", textTransform: "capitalize" }}
            variant="h2"
          >
            {tag.name}
          </Typography>
        </Box>
      </Box>
      <Box component="div" sx={{ maxWidth: 1082, mx: "auto" }}>
        <CompositionsNav />
      </Box>
      <Box
        component="div"
        sx={{ maxWidth: 1082, mx: "auto", mb: 4, display: { md: "flex" } }}
      >
        <Box
          component="div"
          sx={{
            width: { md: "65%" },
            mb: 1.5,
            pr: { md: 1 },
            flex: 1,
          }}
        >
          {posts.map((post: any, index: number) => (
            <BlogPost key={index} post={post.node} />
          ))}
        </Box>
        <Sidebar authors={authors} />
      </Box>

      <Pagination pageContext={pageContext} />

      <Disclaimer />

      <StaticImage
        src="../images/brandLines.svg"
        alt=""
        placeholder="blurred"
        style={{
          position: "absolute",
          right: "0",
          bottom: "0",
          zIndex: -1,
          pointerEvents: "none",
        }}
      />
    </Layout>
  );
};

export default Tag;

export const pageQuery = graphql`
  query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
    ghostTag(slug: { eq: $slug }) {
      ...GhostTagFields
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    allGhostAuthor(
      limit: 6
      filter: {
        postCount: { gte: 4 }
        ghostId: {
          nin: [
            "6222aea739ddd6003d905de6"
            "62604526d04657003dffbc42"
            "61425694e509c6003ea8c3d2"
            "62f272e930b948003dcdaa88"
            "63a3602150234e004d781cad"
            "63594d32be9b30003d3092f3"
          ]
        }
      }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          slug
          name
          profile_image
        }
      }
    }
  }
`;