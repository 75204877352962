import React from "react";
import { Link as RouterLink } from "gatsby";
import { Box, Typography, Link, Button } from "@mui/material";
import Arrow from "../images/arrow2.inline.svg";
import Subscribe from "./Subscribe";

import Search from "./search";
const searchIndices = [{ name: `Pages`, title: `Pages` }];

const Sidebar = ({ authors }) => {
  return (
    <Box
      component="div"
      sx={{ width: { md: "30%" }, maxWidth: { md: "310px" } }}
    >
    </Box>
  );
};

export default Sidebar;
