import React from "react";
import { Link as RouterLink } from "gatsby";
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
} from "react-instantsearch-dom";
import { Box, Typography, Link } from "@mui/material";

const NoResults = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits;

  return hitCount === 0 ? <Box sx={{ padding: "10px" }}>no results</Box> : null;
});

const PageHit = ({ hit, className }) => {
  const url = `/writing/${hit.slug}/`;
  return (
    <Link
      component={RouterLink}
      to={url}
      style={{ textDecoration: "none" }}
      className={className}
    >
      <Typography
        variant="h6"
        sx={{
          color: "#fff",
          fontSize: 20,
          lineHeight: 1.2,
          marginBottom: 1,
          transition: ".3s ease-in-out",
        }}
      >
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </Typography>

      <Typography sx={{ fontSize: 12 }}>
        <Snippet attribute="excerpt" hit={hit} tagName="mark" />
      </Typography>
    </Link>
  );
};

const HitsInIndex = ({ index }) => {
  return (
    <Index indexName={index.name}>
      <Hits className="Hits" hitComponent={PageHit} />
    </Index>
  );
};

const SearchResult = ({ indices, className, show }) => {
  return (
    <>
      {show && (
        <Box
          className={className}
          sx={{
            position: "absolute",
            left: 0,
            top: "54px",
            width: "100%",
            zIndex: 2,
            border: "1px solid #4B608D",
            background: "#051637",
            maxHeight: 250,
            overflow: "auto",
            padding: "0 20px",
            "&::-webkit-scrollbar": {
              width: "18px",
              backgroundColor: "#0C1F47",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#2D457A",
              borderRadius: "10px",
            },
            "& ul": {
              listStyle: "none",
              padding: 0,
              margin: 0,
            },
            "& li": {
              borderBottom: "1px solid #4B608D",
              padding: "15px 0",
              "&:hover h6": {
                color: "#1CE7C2",
              },
            },
            "& mark": {
              backgroundColor: "#4B608D",
              color: "#fff",
            },
          }}
        >
          {indices.map((index) => (
            <HitsInIndex index={index} key={index.name} />
          ))}
          <NoResults />
        </Box>
      )}
    </>
  );
};

export default SearchResult;
