import React from "react";
import { Box, Link } from "@mui/material";
import { Link as RouterLink } from "gatsby";
import Search from "./search";
const btn = {
  position: "relative",
  fontFamily: ["Poppins", "Helvetica", "Arial"].join(","),
  fontWeight: 600,
  fontSize: 12,
  lineHeight: 2,
  mr: 2.2,
  display: "inline-block",
  transition: ".3s ease-in-out",
  "&:hover": {
    color: "#1CE7C2",
  },
};
const nav = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  px: { xs: 2, md: 4 },
  py: { xs: 2, md: 0 },
  height: { md: 54 },
  border: "1px solid #4B608D",
  background: "#0D1F46",
  mb: 1.1,
};

const btnActive = {
  color: "#1CE7C2",
};
const searchIndices = [{ name: `Pages`, title: `Pages` }];

const CompositionsNav = () => {
  return (
    <Box component="div" sx={{display: "flex", gap: 1}}>
      <Box component="div" sx={nav}>
        <Link
          sx={btn}
          component={RouterLink}
          activeStyle={btnActive}
          to="/writing"
        >
          All
        </Link>
        <Link
          sx={btn}
          component={RouterLink}
          activeStyle={btnActive}
          to="/writing/announcements"
        >
          Announcements
        </Link>
        <Link
          sx={btn}
          component={RouterLink}
          activeStyle={btnActive}
          to="/writing/research"
        >
          Research
        </Link>
        <Link
          sx={btn}
          component={RouterLink}
          activeStyle={btnActive}
          to="/writing/security"
        >
          Security
        </Link>

        <Link
          sx={btn}
          component={RouterLink}
          activeStyle={btnActive}
          to="/writing/firedancer"
        >
          Firedancer
        </Link>
        <Link
          sx={btn}
          component={RouterLink}
          activeStyle={btnActive}
          to="/writing/gaming"
        >
          Gaming
        </Link>

        <Link
          sx={btn}
          component={RouterLink}
          activeStyle={btnActive}
          to="/writing/infrastructure"
        >
          Infrastructure
        </Link>
        <Link
          sx={btn}
          component={RouterLink}
          activeStyle={btnActive}
          to="/writing/silo"
        >
          Silo
        </Link>
        <Link
          sx={btn}
          component={RouterLink}
          activeStyle={btnActive}
          to="/writing/thepit"
        >
          the Pit
        </Link>
      </Box>
      <Search indices={searchIndices} />

    </Box>
  );
};

export default CompositionsNav;
